import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getTariffType,
  getUserType,
  setBlockRatesUsage,
  setEndDate,
  setPeakOffPeakUsage,
  setSingleRateUsage,
  setStartDate,
  setTariffType,
} from '../utils/localStorage/localStorageFunctions';
import { TariffTypeLsType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const AboutYourBill = () => {
  const [initialFormValues] = useState(() => {
    const tariffTypeLS = getTariffType();
    return { tariff: tariffTypeLS || '' };
  });

  const handleNext = (values: typeof initialFormValues) => {
    const tariffType = values.tariff as TariffTypeLsType;
    const tariffTypeLS = getTariffType();

    setTariffType(tariffType);

    if (tariffTypeLS && tariffType !== tariffTypeLS) {
      setStartDate(null);
      setEndDate(null);
      setPeakOffPeakUsage(null);
      setSingleRateUsage(null);
      setBlockRatesUsage(null);
    }

    if (tariffType === 'single') {
      navigateAndSetActiveStep(StepUrl.URL_SINGLE_RATE_TARIFF);
      return;
    }
    if (tariffType === 'peakOff') {
      navigateAndSetActiveStep(StepUrl.URL_PEAK_OFF_PEAK_TARIFF);
    }
    if (tariffType === 'block') {
      navigateAndSetActiveStep(StepUrl.URL_BLOCK_RATES_TARIFF);
    }
  };

  const handleBack = () => {
    const userType = getUserType();

    if (userType === 'business') {
      navigateAndSetActiveStep(StepUrl.URL_DISTRIBUTOR_CHOICE);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_YOUR_HOUSEHOLD);
  };

  usePreloadPageData(
    StepUrl.URL_SINGLE_RATE_TARIFF,
    StepUrl.URL_PEAK_OFF_PEAK_TARIFF,
    StepUrl.URL_BLOCK_RATES_TARIFF,
    StepUrl.URL_DISTRIBUTOR_CHOICE,
    StepUrl.URL_YOUR_HOUSEHOLD
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={5}
        pageUrl={StepUrl.URL_ABOUT_YOUR_BILL}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">About your bill</h2>
                <p className="sub-heading">
                  Your bill will indicate how you are charged.
                </p>
                <p className="my-2 text-label">
                  What type of tariff are you on?
                </p>
                <RadioButtonGroup className="my-2">
                  <RadioButton name="tariff" value="single" id="rd-1">
                    I have a single rate
                  </RadioButton>
                  <RadioButton name="tariff" value="peakOff" id="rd-2">
                    I have peak/off peak rates
                  </RadioButton>
                  <RadioButton name="tariff" value="block" id="rd-3">
                    I have block rates
                  </RadioButton>
                </RadioButtonGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={props.values.tariff === ''}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default AboutYourBill;
